<style>
  .PageTitle {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  h1 {
    margin: 0;
  }

  .holder > :first-child {
    margin-bottom: 10px;
  }

  @media (min-width: 640px) {
    .holder {
      margin-top: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .holder > :first-child {
      margin-bottom: 0;
    }
  }
</style>

<div class="PageTitle uk-container">
  <div class="holder">
    <div>
      <h1>
        <slot />
      </h1>
    </div>
    <slot name="right" />
  </div>
</div>
