<style>
  .page-footer {
    margin-top: 2rem;
    background: hsla(0, 0%, 92%, 1);
    height: 5rem;
  }
  .uk-container {
    height: 100%;
  }
  .content {
    height: 100%;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
</style>

<div class="page-footer">
  <div class="uk-container">
    <div class="content">
      <div>Copyright 2020, Xcalibra</div>
      <div>
        <a href="#" uk-totop uk-scroll />
      </div>
    </div>
  </div>
</div>
